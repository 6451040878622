import * as React from "react";
import {Button, Container, Grid, Header, Segment} from "semantic-ui-react";
import "../../css/intro.css";
import Hero from '../pages/hero03';

import "animate.css/animate.compat.css";
import {navigateTo} from "../../helpers/router";
import {t} from "../../i18n";
import { StaticImage } from 'gatsby-plugin-image';
import LandingSection01 from "../parts/LandingSection01";
import LandingBackgroundSection05 from "../backgrounds/landings/Section05";
import LandingBackgroundSection02 from "../backgrounds/landings/Section02";
import LandingBackgroundSection03 from "../backgrounds/landings/Section03";
import LandingBackgroundSection04 from "../backgrounds/landings/Section04";
import IntroListItem from '../utils/IntroListItem';
import NewFooter from './newfooter';
import SEO from "../seo";

export default class Landing03 extends React.Component {
  render() {
    return (
      <Segment basic className="overall-segment">
        <SEO title="Suplementy wspomagające przy dietach"
          description="Suplementy stosowane wspomagająco przy dietach odchudzających Pure4me dobierane są specjalnie pod Twoją wagę, wzrost, wiek i tryb życia. Uzupełnią dietę i dodadzą energii."
        />
        <Hero/>
        <LandingSection01>
          <Header as={"h2"} id='intro-h2'>Coś innego niż tabletki<br/> <b>na odchudzanie</b></Header>
          <p id={'section-01-01-lead'}>Przebywasz na diecie odchudzającej lub w celu utraty zbędnych kilogramów zredukowałaś porcje jedzenia i zaczęłaś uprawiać sport? Chcesz schudnąć w zdrowy sposób bez zaburzenia homeostazy organizmu dotyczącej wartościowych minerałów i witamin? Pomimo przyjmowania mniejszej ilości pożywienia, nadal chcesz mieć energię, żeby dobrze czuć się każdego dnia?</p>
          <p>Suplementy stosowane wspomagająco przy dietach odchudzających Pure4me w wygodnych saszetkach zawierają właśnie to, czego potrzebujesz: doskonale przyswajalny, kompleksowy mix multiwitaminowy z minerałami dla kobiet. Podczas gdy typowe środki odchudzające koncentrują się wyłącznie na przyspieszeniu metabolizmu lub hamowaniu apetytu, nasze suplementy kompleksowo podchodzą do tematu Twojego zdrowia i dobrego samopoczucia.</p>
        </LandingSection01>

        <LandingBackgroundSection02>
          <Segment basic id="section-01-02">
            <Container id="section-01-02-container">
              <Grid stackable>
                <Grid.Row>
                  <Grid.Column width={8} verticalAlign="middle">
                    <Header as={"h2"} id='intro-h3'>Dobrane <b>specjalnie dla Ciebie</b> suplementy stosowane wspomagająco <b>przy dietach odchudzających Pure4me</b></Header>
                    <p>Nasze suplementy wspomagające zachowanie sprawnego układu nerwowego oraz mięśniowego są personalizowane specjalnie pod Ciebie. Oznacza to, że w swoich saszetkach znajdziesz minerały i witaminy stosowane wspomagająco przy dietach odchudzających dobrane do Twojego wzrostu, wagi, wieku, trybu życia oraz tego, co jesz na swojej diecie. Będą to zatem ilości dobrane dla Ciebie, a nie średnie zalecane ilości, jakie zawierają typowe suplementy w aptekach.</p>
                  </Grid.Column>
                  <Grid.Column width={8} verticalAlign="middle">
                    <StaticImage src="../../img/landings/03/section_3.png" alt="Suplementy" placeholder="none"/>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Container>
          </Segment>
        </LandingBackgroundSection02>

        <Segment basic style={{padding: "42px 0"}}>
          <Container>
            <Grid stackable>
              <Grid.Row>
                <Grid.Column width={8} verticalAlign="middle">
                  <StaticImage src="../../img/landings/03/section_4.png" alt="Suplementy" placeholder="none"/>
                </Grid.Column>
                <Grid.Column width={8} verticalAlign="middle">
                  <Header as={"h2"} id='intro-h3'><b>Minerały i witaminy</b> wspierające <b>w dietach odchudzających</b> w wygodnych saszetkach. Zero cukru i konserwantów.</Header>
                  <p>Nasze suplementy diety dla mężczyzn nie zawierają cukru, konserwantów ani żadnych sztucznych wypełniaczy. Są słodzone wartościową stewią i bezpiecznym nawet dla cukrzyków ksylitolem. Wiele z nich posiada opracowaną w
                    naszych laboratoriach aktywną metylowaną formę, która sprawia, że wykazują zwiększoną biodostępność. Pure4me, jako marka znanego producenta farmaceutycznego Natur Produkt Zdrovit, łączy w sobie innowacyjność, wiedzę
                    ekspertów i bezpieczeństwo. Posiadamy prestiżowe Certyfikaty Jakości Narodowego Instytutu Leków. Zobacz, jakie witaminy i suplementy dla mężczyzn po 50, 40 i 30 najczęściej pojawiają się w kompozycjach Pure4me.</p>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Container>
        </Segment>

        <LandingBackgroundSection03>
          <Segment basic id="section-01-03">
            <Container id="section-01-03-container">
              <Grid stackable relaxed={"very"} centered>
                <Grid.Row>
                  <Grid.Column width={5}>
                    <Grid>
                      <Grid.Row>
                        <Grid.Column>
                          <StaticImage src='../../img/landings/03/ico_1.png' alt="Witaminy z grupy B" placeholder='none' />
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row>
                        <Grid.Column>
                          <Header as={"h3"}>Witaminy z grupy B <b>dla zdrowego metabolizmu</b></Header>
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row>
                        <Grid.Column><p>Cały niezbędny kompleks. Witamina B1, B2, B3,
                          B5, B6 oraz B12 to witaminy stosowane
                          wspomagająco przy dietach odchudzających
                          poprzez zapewnienie prawidłowego,
                          zdrowego metabolizmu energetycznego.
                          Dzięki nim składniki odżywcze przetwarzane
                          są w energię zamiast być gromadzonymi jako
                          tłuszcz.</p></Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </Grid.Column>

                  <Grid.Column width={5}>
                    <Grid>
                      <Grid.Row>
                        <Grid.Column>
                          <StaticImage src='../../img/landings/03/ico_2.png' alt="Kwas foliowy 5-MTHF" placeholder='none' />
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row>
                        <Grid.Column>
                          <Header as={"h3"}>Kwas foliowy 5-MTHF <b>dla sprawnych mięśni</b></Header>
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row>
                        <Grid.Column><p>Ta aktywna metylowana forma kwasu
                          foliowego wykazuje zwiększoną
                          biodostępność i odgrywa fundamentalną rolę
                          w powstawaniu neuroprzekaźników oraz tak
                          istotnych, szczególnie dla sportowców,
                          aminokwasów, z których zbudowane są
                          białka. A jak wiadomo, białka są
                          podstawowym budulcem wszystkich mięśni i
                          zapewniają ich sprawne funkcjonowanie.</p></Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </Grid.Column>

                  <Grid.Column width={5}>
                    <Grid>
                      <Grid.Row>
                        <Grid.Column>
                          <StaticImage src='../../img/landings/03/ico_3.png' alt="Magnez" placeholder='none' />
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row>
                        <Grid.Column>
                          <Header as={"h3"}>Magnez <b>dla zmniejszenia uczucia zmęczenia</b></Header>
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row>
                        <Grid.Column><p>Magnez to suplement niezwykle istotny dla
                          Twojego samopoczucia: przyczynia się do
                          zmniejszenia uczucia znużenia i zmęczenia,
                          które często towarzyszą osobom
                          przebywającym na diecie odchudzającej.</p></Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row/>
                <Grid.Row>
                  <Grid.Column width={6}>
                    <Grid>
                      <Grid.Row>
                        <Grid.Column>
                          <StaticImage src='../../img/landings/03/ico_4.png' alt="Żelazo" placeholder='none' />
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row>
                        <Grid.Column>
                          <Header as={"h3"}>Żelazo <b>dla prawidłowego spalania tłuszczu</b></Header>
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row>
                        <Grid.Column><p>Żelazo nie tylko pomaga w funkcjonowaniu układu odpornościowego i w produkcji czerwonych krwinek i hemoglobiny, ale jest niezwykle pożyteczne jako suplement wspomagający przy diecie i wzmożonej aktywności fizycznej. Zapewnia transport tlenu do mięśni, bez którego wystarczającej ilości mięśnie nie mogą się prawidłowo kształtować i spalać tłuszczu. </p></Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </Grid.Column>

                  <Grid.Column width={6}>
                    <Grid>
                      <Grid.Row>
                        <Grid.Column>
                          <StaticImage src='../../img/landings/03/ico_5.png' alt="Wapń, potas, jod, witamina C i E" placeholder='none' />
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row>
                        <Grid.Column>
                          <Header as={"h3"}>Wapń, potas, jod, witamina C i E <b>dla wielu innych, ważnych aspektów</b></Header>
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row>
                        <Grid.Column><p>Wapń pomaga w utrzymaniu zdrowych zębów i kości. Jod wspomaga układ nerwowy, a potas prawidłowe ciśnienie krwi oraz funkcjonowanie mięśni. Witamina C wspiera odporność, która może być zagrożona podczas osłabienia organizmu dietą zmniejszającą ilość spożywanych kalorii w ciągu dnia. Z kolei witamina E chroni komórki przed stresem oksydacyjnym.</p>
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </Grid.Column>

                </Grid.Row>
              </Grid>
            </Container>
          </Segment>
        </LandingBackgroundSection03>

        <LandingBackgroundSection04>
          <Segment basic id="section-01-04">
            <Container id="section-01-04-container">

              <Grid stackable relaxed={'very'}>
                <Grid.Row>
                  <Grid.Column width={8}>
                    <Header as={'h2'} id='intro-h3'><b>Pure4me</b><br/> suplementy, które kompleksowo wesprą Cię <b>podczas trudnych dni diety</b></Header>
                    <p> Suplementy stosowane wspomagająco przy dietach odchudzających bywają różne i nie zawsze ich producenci pamiętają o tym, że Twoje ciało i
                      Twój dobrostan zależy od wielu składników, które zazwyczaj przyjmujesz w jedzeniu. Dieta odchudzająca i towarzyszące jej ćwiczenia, których
                      celem jest spalenie nadmiaru tkanki tłuszczowej, mogą sprawić, że pojawią się u Ciebie niedobory podstawowych witamin i minerałów, które
                      utrzymują Cię sprawną, zdrową, silną i pełną energii.
                    </p>
                    <p id={'section-01-01-lead'}>Nasze suplementy stosowane wspomagająco przy dietach
                      odchudzających, zawierają minerały i witaminy, które:</p>
                    <div>
                      <IntroListItem><b>dodadzą Ci energii i pomogą usunąć znużenie</b></IntroListItem>
                      <IntroListItem>są kluczowe <b>dla prawidłowego, zdrowego metabolizmu</b></IntroListItem>
                      <IntroListItem>zadbają o prawidłowe <b>funkcjonowanie Twojego układu nerwowego i odpornościowego</b></IntroListItem>
                      <IntroListItem>utrzymają <b>prawidłowy poziom składników</b>, który może być zagrożony przez to, że spożywasz w tej chwili mniejsze porcje jedzenia</IntroListItem>
                      <IntroListItem>wesprą sprawną <b>pracę Twoich mięśni</b> podczas treningów</IntroListItem>
                      <IntroListItem>poprawią Twoje <b>ogólne samopoczucie</b></IntroListItem>
                    </div>
                  </Grid.Column>
                  <Grid.Column width={8} verticalAlign="middle">
                    <StaticImage src='../../img/landings/03/section_6.png' alt='Pozytywy suplementacji' placeholder='none' />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Container>
          </Segment>
        </LandingBackgroundSection04>

        <LandingBackgroundSection05>
          <Segment basic id="section-01-05">
            <Container id="section-01-05-container">
              <Grid stackable relaxed={'very'}>
                <Grid.Column width={8} verticalAlign="middle">
                  <StaticImage src='../../img/landings/section_7.png' alt='Suplementy' placeholder='none'/>
                </Grid.Column>
                <Grid.Column width={8}>
                  <Header as={"h2"} id='intro-h2'>Zamów suplementy stosowane wspomagająco przy dietach odchudzających <b>Pure4me</b></Header>
                  <p>
                    Jako, że nasze zestawy suplementów są personalizowane i dobierane pod Ciebie, nabywa się je inaczej niż tabletki na odchudzanie w standardowych internetowych sklepach. Najpierw musimy otrzymać kilka informacji od Ciebie o Twojej wadze i wieku oraz trybie życia i diecie, na której jesteś. Wypełnij zatem opracowaną przez nasz zespół naukowy ankietę, a zwrotnie odeślemy Ci propozycję zestawu, który możesz nabyć. Nie obawiaj się - wypełnienie ankiety nie zobowiązuje Cię do zakupu. 
                  </p>
                  <p><b>Twój spersonalizowany suplement diety będzie na Ciebie czekać, kiedy będziesz go potrzebować.</b></p>
                  <Grid>
                    <Button primary className="btn-primary shadow-light newintro-primary-button" id="btn-check"
                            onClick={() => navigateTo('/survey')}>
                      {t("sprawdź swój skład")}!
                    </Button>
                  </Grid>
                </Grid.Column>
              </Grid>
            </Container>
          </Segment>
        </LandingBackgroundSection05>

        <span id="footer">
          <NewFooter/>
        </span>
      </Segment>
    );
  }
}