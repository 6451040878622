import * as React from "react";
import {
  Button, Container, Grid, Header, Segment
} from "semantic-ui-react";
import {t} from "../../i18n";
import 'animate.css/animate.css'
import {navigateTo} from "../../helpers/router";
import LandingBackgroundHero03 from "../backgrounds/landings/Hero03";

export default class Hero extends React.Component {
  render() {
    return (
      <LandingBackgroundHero03>
        <Segment basic id="landing-3-banner" className="intro-fullwidth-component">
          <Container>
            <Segment basic id='hero-container-01'>
              <Grid stackable>
                <Grid.Column computer={8} mobile={16} tablet={16}>
                  <Header as="h1" id='hero-title'>Suplementy stosowane wspomagająco <b>przy dietach odchudzających</b></Header>
                  <p id="hero-paragraph">Każdy z nas jest wyjątkowy i taki też jest <b>pure4me</b>. Suplement w 100% <b>spersonalizowany</b>, pod Twoje potrzeby podczas wyjątkowych dni diety odchudzającej.</p>
                  <Grid>
                    <Button primary className="btn-primary shadow-light newintro-primary-button" id="btn-hero"
                            onClick={() => navigateTo('/survey')}>
                      {t("sprawdź swój skład")}!
                    </Button>
                  </Grid>
                </Grid.Column>
              </Grid>
            </Segment>
          </Container>
        </Segment>
      </LandingBackgroundHero03>
    );
  }
}